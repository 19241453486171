<template>
  <div class="mb-4">
    <div class="row text-left text-lg-center">
      <h2 class="col-12 py-2">
        To further reduce your CO<sup>2</sup> footprint, Go Solar!
      </h2>
    </div>
    <div class="row pros-cons">
      <div class="col-12">
        <div class="row d-lg-none cons">
          <div class="col-12">
            <h3>CONS</h3>
            <ul>
              <li>High upfront cost</li>
              <li>Power generation dependent on weather</li>
              <li>Solar panels become less effective over time</li>
            </ul>
          </div>
        </div>
        <div class="row d-lg-none pros">
          <div class="col-12">
            <h3>PROS</h3>
            <ul>
              <li>26% tax credit for solar purchases</li>
              <li>Reduce or eliminate your electric bill</li>
              <li>Reduce your emissions</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row solar-ad py-4 px-5">
      <div class="col-5 order-2 order-lg-1">
        <img
          src="~Images/solar_panels.jpg"
          class="img-fluid border"
          alt="Neighborhood home with SunPower solar panels installed"
        />
      </div>
      <div class="col-7 order-1 order-lg-2">
        <h2 class="pb-2">Get a free solar estimate from SunPower</h2>
        <h3 class="pb-3">SunPower Solar Panels</h3>
        <p>
          reduce CO<sup>2</sup> emissions and pollution, improve home value
          &amp; can reduce or eliminate your electric bill.
        </p>
        <a
          id="SunPowerSolarPanelLink"
          class="btn button"
          href="https://track.flexlinkspro.com/g.ashx?foid=156375.95519.4611686018427588186&trid=1255557.212534&foc=16&fot=9999&fos=5"
          target="_blank"
          >learn more <span class="accent">&raquo;</span></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 1.625rem;
  margin-bottom: 0;
  padding-bottom: 20px;
  background-color: #cef0e0;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
    padding-left: 13px;
    padding-right: 5px;
  }
}

.solar-ad {
  background-color: #cef0e0;
  @include media-breakpoint-up(lg) {
    h3 {
      font-size: 1.375rem;
      color: #404040;
    }

    p {
      font-size: 0.75rem;
      font-weight: normal;
    }
    img.border {
      border: 3px solid #c1c5c8;
    }
  }
  @include media-breakpoint-down(md) {
    h3 {
      font-size: 0.875rem;
    }
    p {
      font-size: 0.75rem;
    }
    background-color: #cef0e0;

    img.border {
      border: 3px solid #c5c7c2 !important;
    }
  }
}

.pros-cons {
  @include media-breakpoint-up(lg) {
    background: url("~Images/solar_pros_cons.jpg") center no-repeat;
    height: 200px;
  }

  background-color: #cef0e0;

  ul {
    margin-left: -10px;
  }
  h3 {
    font-size: 0.875rem;
    padding-left: 13px;
    font-family: $primary-header-font-family;
  }
  p {
    font-size: 0.75rem;
  }

  .cons h3 {
    color: #8d151e;
  }
  .pros h3 {
    color: #234a1e;
  }
}

.button.btn {
  border-radius: 100px;
  font-weight: bold;
  margin-top: 83px;
  padding: 2px 15px 2px 15px !important;
  font-size: 1rem !important;
  color: black !important;
  background-color: #ecf7f1 !important;
  height: 31px;
}
</style>
